import Hero from './Hero';
import Work from './Work';
import '../../styles/App.css';

function Homepage(){
    return(
        <div className='App'>
        <Hero></Hero>
        <Work></Work>
        </div>
    )
}
export default Homepage